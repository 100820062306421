@font-face {
  font-family: 'Karla';
  src: local('Karla'), url(../fonts/Karla/Karla-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Karla';
  src: local('Karla'), url(../fonts/Karla/Karla-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Karla';
  src: local('Karla'), url(../fonts/Karla/Karla-ExtraBold.ttf) format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Karla';
  src: local('Karla'), url(../fonts/Karla/Karla-ExtraBoldItalic.ttf) format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Karla';
  src: local('Karla'), url(../fonts/Karla/Karla-ExtraLight.ttf) format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Karla';
  src: local('Karla'), url(../fonts/Karla/Karla-ExtraLightItalic.ttf) format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Karla';
  src: local('Karla'), url(../fonts/Karla/Karla-Italic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Karla';
  src: local('Karla'), url(../fonts/Karla/Karla-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Karla';
  src: local('Karla'), url(../fonts/Karla/Karla-LightItalic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Karla';
  src: local('Karla'), url(../fonts/Karla/Karla-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Karla';
  src: local('Karla'), url(../fonts/Karla/Karla-MediumItalic.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Karla';
  src: local('Karla'), url(../fonts/Karla/Karla-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Karla';
  src: local('Karla'), url(../fonts/Karla/Karla-SemiBold.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Karla';
  src: local('Karla'), url(../fonts/Karla/Karla-SemiBoldItalic.ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
}

body {
  font-family: Karla, Roboto, Helvetica, Arial, sans-serif;
}

h1, h2, h3, h4, h5 {
  font-family: Karla, Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  margin-top: 0.5rem;
}

.card-header {
  font-family: Karla, Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
}
