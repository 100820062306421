.hide {
  display: none;
}

.display {
  display: block;
}


body {
  background-color: var(--mdb-black);

  h3, h5 {
    color: var(--mdb-gray-300);
  }

  p {
    color: var(--mdb-gray-300);
  }
}

/********** NAVBAR **********/

/********** Dropdown Menu **********/
.dropdown {
  a {
    &.nav-link {
      color: var(--mdb-gray-300);

      &:hover {
        color: var(--mdb-white)
      }
    }
  }
}

div.dropdown-menu {
  margin-top: 10px;
  border-radius: 0.2rem;

  a {
    color: var(--mdb-gray-300);

    &.hover {
      color: var(--mdb-black);
    }

    &.no-hover {
      color: var(--mdb-gray-300);
    }

    &:first-child {
      border-top-left-radius: 0.2rem;
      border-top-right-radius: 0.2rem;

      a.dropdown-item {
        border-top-left-radius: 0.2rem;
        border-top-right-radius: 0.2rem;
      }
    }

    &:last-child {
      border-bottom-left-radius: 0.2rem;
      border-bottom-right-radius: 0.2rem;

      a.dropdown-item {
        border-bottom-left-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
      }
    }

    &:hover {
      color: var(--mdb-green);
    }
  }
}

/********** LAYOUT **********/

/********** Containers **********/
.green-container, .green-row {
  background-color: var(--mdb-green);

  h2 {
    color: var(--mdb-gray-300);
  }
}

/********** Card **********/
.card {
  background-color: var(--mdb-black);
  border: 1px solid var(--mdb-gray-300);

  img {
    object-fit: cover;
  }

  .card-header {
    color: var(--mdb-green);
    border-bottom: 1px solid var(--mdb-gray-600);
  }

  .card-body {
    .card-text {
      color: var(--mdb-gray-300);
    }

    .card-link {
      color: var(--mdb-green);
    }
  }
}

.hr {
  color: var(--mdb-gray-300);
}

p {
  margin-bottom: 1rem;
}

.info-only {
  color: var(--mdb-gray-300);

  a {
    color: var(--mdb-green);
  }

  .address {
    p {
      margin-bottom: 0;
    }

    .contact-info {
      padding-top: 20px;

      p, a {
        color: var(--mdb-green);
      }
    }
  }
}